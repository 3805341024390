import { Component } from '@angular/core';

@Component({
  selector: 'app-audition',
  templateUrl: './audition.component.html',
  styleUrls: ['./audition.component.scss']
})
export class AuditionComponent {

}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private fb: FormBuilder, public router: Router) {
    this.loginForm = this.fb.group({
       email: ["", [Validators.required]], //, Validators.email
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  login() {
    if (this.loginForm.value["email"] == "scanuser" && this.loginForm.value["password"] == "scan123") {
      let user = {
        email: "scanuser",
        password: "scan123",
        name: "Scan User",
      };
      localStorage.setItem("scanuser", 'Y');
      localStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(["/audition/userscan"]);
    }else  if (this.loginForm.value["email"] == "admin" && this.loginForm.value["password"] == "admin123") {
      let user = {
        email: "Admin",
        password: "admin123",
        name: "test user",
      };
      localStorage.setItem("scanuser", 'N');
      localStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(["/audition/userlist"]);
    }else  if (this.loginForm.value["email"] == "admin" && this.loginForm.value["password"] == "admin123") {
      let user = {
        email: "Admin",
        password: "admin123",
        name: "test user",
      };
      localStorage.setItem("scanuser", 'N');
      localStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(["/audition/userlist"]);
    }
  }

  showPassword(){
    this.show = !this.show
  }
}
